import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { removeOrder } from "../../redux/slices/poster/orderSlice/OrderSlice";
import "./ModalThank.scss";

const ModalThank = ({ orderPoster }) => {
	const order = orderPoster;
	const dispatch = useDispatch();
	const onClickClose = () => {
		dispatch(removeOrder());
	};
	return (
		<div className="modal-thank">
			<div className="overlay-thank" onClick={onClickClose}></div>
			<div className="modal-inner">
				<div className="heading">
					<h2>Дякуємо за замовлення</h2>
					<p>
						Замовлення №
						{order.order.response
							? order.order.response.incoming_order_id
							: ""}{" "}
						прийнято. Найближчим часом з вами зв&#39;яжеться наш
						оператор для підтвердження замовлення.{" "}
					</p>
				</div>

				<button
					className="a-btn e--gold mt-4 pe-4 ps-4"
					style={{ minWidth: "160px" }}
					onClick={onClickClose}
				>
					Закрити
				</button>
			</div>
		</div>
	);
};
ModalThank.propTypes = {
	orderPoster: PropTypes.object,
};
export default ModalThank;
