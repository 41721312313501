//import React from 'react'
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import { NavLink, Link } from "react-router-dom";

const Categories = ({ categories, value, priority }) => {
	const categoriesList = categories.items;
	const priorityList = priority;
	const priorityItems = sortBy(priorityList.items, function (o) {
		return o.priority;
	});
	return (
		<div className="m-tabs categories">
			<ul className="m-tabs-list">
				<li>
					<Link
						to={`/${value}`}
						className={`${
							location.pathname === `/${value}` ? "active" : ""
						}`}
					>
						<button>Всі</button>
					</Link>
				</li>

				{priorityItems.map((priority) =>
					categoriesList
						.filter(
							(cat) =>
								cat.category_id == priority.id &&
								cat.parent_category === value &&
								cat.visible[0].visible === 1
						)
						.map((item) => (
							<li key={item.category_id}>
								<NavLink
									to={`/${item.parent_category}/${item.category_id}`}
								>
									<button>{item.category_name}</button>
								</NavLink>
							</li>
						))
				)}
			</ul>
		</div>
	);
};

Categories.propTypes = {
	categories: PropTypes.object,
	priority: PropTypes.object,
	value: PropTypes.string,
};

export default Categories;
