import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { $authHost } from "../../../http";
import axios from "../../../http/axios";

export const fetchCategoryPriority = createAsyncThunk(
	"categoryPriority/fetchCategoryPriority",
	async () => {
		const { data } = await axios.get("api/category_priority");
		return data;
	}
);
export const createCategoryPriority = createAsyncThunk(
	"categoryPriority/createCategoryPriority",
	async (item) => {
		const { data } = await $authHost.post("api/category_priority", item);
		return data;
	}
);

const initialState = {
	items: [],
	status: "",
	createStatus: "",
};

const categoryPrioritySlice = createSlice({
	name: "categoryPriority",
	initialState,
	reducer: {},
	extraReducers: {
		[fetchCategoryPriority.pending]: (state) => {
			state.items = [];
			state.status = "loading";
		},
		[fetchCategoryPriority.fulfilled]: (state, action) => {
			state.items = action.payload;
			state.status = "loaded";
		},
		[fetchCategoryPriority.rejected]: (state) => {
			state.items = [];
			state.status = "error";
		},
		[createCategoryPriority.pending]: (state) => {
			state.createStatus = "loading";
		},
		[createCategoryPriority.fulfilled]: (state, action) => {
			state.items.push(action.payload);
			state.createStatus = "loaded";
		},
		[createCategoryPriority.rejected]: (state) => {
			state.createStatus = "error";
		},
	},
});

export const categoryPriority = categoryPrioritySlice.reducer;
