import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchCategoriesPoster } from "../../../redux/slices/poster/productsSlice/CategoriesSlice";
import axios from "../../../http/axios";
import NumberFormat from "react-number-format";
import { $authHost } from "../../../http";
import "./ProductsCategories.scss";

const ProductsCategories = () => {
	let [activeCat, setActiveCat] = useState();
	const categories = useSelector((state) => state.categoriesPoster);
	const dispatch = useDispatch();
	const [priorityList, setPriorityList] = useState([]);
	useEffect(() => {
		async function fetchData() {
			try {
				const { data } = await axios.get("api/category_priority");
				setPriorityList(data);
			} catch (error) {
				console.log(
					"Помилка при отриманні даних про пріорітети категорій"
				);
			}
		}

		fetchData();
	}, []);
	const handleCreatePriority = async (e) => {
		try {
			await $authHost.post("api/category_priority", e);
		} catch (err) {
			return err.response.data;
		}
	};
	useEffect(() => {
		dispatch(fetchCategoriesPoster());
	}, [dispatch]);
	return (
		<div className="categories">
			<div className="categories-list" style={{ minWidth: "440px" }}>
				<div className="item title">
					<div
						className="img"
						style={{
							minWidth: "120px",
						}}
					>
						Зображення
					</div>
					<div className="name">Назва Категорії</div>
					<div
						style={{
							justifyContent: "center",
							minWidth: "120px",
						}}
					>
						Пріорітет
					</div>
				</div>
				{categories.status === "loaded" &&
					categories.items
						.filter((item) => item.parent_category === "0")
						.map((item) => (
							<>
								<div
									key={item.category_id}
									className={
										activeCat === item.category_id
											? "item active"
											: "item"
									}
								>
									<div
										className="img"
										style={{
											minWidth: "120px",
										}}
										onClick={() =>
											activeCat === item.category_id
												? setActiveCat((activeCat = ""))
												: setActiveCat(
														(activeCat =
															item.category_id)
												  )
										}
									>
										<img
											src={
												process.env
													.REACT_APP_POSTER_API_URL +
												item.category_photo
											}
										/>
									</div>
									<div
										className="name"
										onClick={() =>
											activeCat === item.category_id
												? setActiveCat((activeCat = ""))
												: setActiveCat(
														(activeCat =
															item.category_id)
												  )
										}
									>
										{item.category_name}
									</div>
									<div
										className="inputs"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											minWidth: "120px",
										}}
									>
										{/*<button
                                            disabled
                                            type="button"
                                            className="update"
                                        >
                                            <Link to="/admin">Редагувати</Link>
                                        </button>
                                        <button
                                            disabled
                                            type="button"
                                            className="delete"
                                        >
                                            <Link to="/admin">Видалити</Link>
                                        </button>*/}

										{priorityList.map((card) => {
											if (card.id == item.category_id) {
												return (
													<>
														<NumberFormat
															placeholder="1"
															style={{
																backgroundColor:
																	"transparent",
																width: "64px",
																height: "80%",
																color: "white",
																textAlign:
																	"center",
																appearance:
																	"none",
															}}
															value={
																card.priority
															}
															onChange={(e) => {
																handleCreatePriority(
																	{
																		id: item.category_id,
																		priority:
																			e
																				.target
																				.value,
																	}
																);
															}}
														/>
													</>
												);
											}
										})}
										<NumberFormat
											value={1}
											style={{
												backgroundColor: "transparent",
												width: "64px",
												height: "80%",
												color: "white",
												textAlign: "center",
												appearance: "none",
											}}
											onChange={(e) =>
												handleCreatePriority({
													id: item.category_id,
													priority: e.target.value,
												})
											}
										/>
										{/*{console.log(
                                            priorityList.find(
                                                (card) =>
                                                    card.id == item.category_id
                                            )
                                        )}*/}
										{/*<button
                                            className="create"
                                            disabled
                                            style={{
                                                margin: '0',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            <Link to="create">Зберегти</Link>
                                        </button>*/}
									</div>
								</div>
								{activeCat === item.category_id &&
									categories.items
										.filter(
											(it) =>
												it.parent_category ===
												item.category_id
										)
										.map((subcat) => (
											<div
												className="item item-sub"
												key={subcat.category_id}
											>
												<div
													className="img"
													style={{
														minWidth: "120px",
													}}
												></div>
												<div className="name">
													<span>
														{subcat.category_name}
													</span>
												</div>
												<div
													className="inputs"
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
														minWidth: "120px",
													}}
												>
													{/*<button
                                            disabled
                                            type="button"
                                            className="update"
                                        >
                                            <Link to="/admin">Редагувати</Link>
                                        </button>
                                        <button
                                            disabled
                                            type="button"
                                            className="delete"
                                        >
                                            <Link to="/admin">Видалити</Link>
                                        </button>*/}
													{priorityList.map(
														(card) => {
															if (
																card.id ==
																subcat.category_id
															) {
																return (
																	<>
																		<NumberFormat
																			placeholder="00"
																			style={{
																				backgroundColor:
																					"transparent",
																				width: "64px",
																				height: "80%",
																				color: "white",
																				textAlign:
																					"center",
																				appearance:
																					"none",
																			}}
																			value={
																				card.priority
																			}
																			onChange={(
																				e
																			) => {
																				handleCreatePriority(
																					{
																						id: subcat.category_id,
																						priority:
																							e
																								.target
																								.value,
																					}
																				);
																			}}
																		/>
																	</>
																);
															}
														}
													)}
													<NumberFormat
														value={0}
														style={{
															backgroundColor:
																"transparent",
															width: "64px",
															height: "80%",
															color: "white",
															textAlign: "center",
															appearance: "none",
														}}
														onChange={(e) =>
															handleCreatePriority(
																{
																	id: subcat.category_id,
																	priority:
																		e.target
																			.value,
																}
															)
														}
													/>
												</div>
											</div>
										))}
							</>
						))}
			</div>
			{/*<button className="create" disabled>
                <Link to="create">Додати категорію</Link>
            </button>*/}
		</div>
	);
};

export default ProductsCategories;
