const NotFound = () => {
    return (
        <>
            <div className="container mt-5">
                <h1>404</h1>
            </div>
        </>
    )
}

export default NotFound
