//import ProductsList from '../../components/ProductsList/ProductsList_copy'
import ProductsList from "../../components/ProductsList/ProductsList";
import { useDispatch, useSelector } from "react-redux";
//import axios from '../../http/axios'

import Hero from "../../components/Hero/Hero";
import { useEffect } from "react";
//import { fetchProducts } from '../../redux/slices/products/productsSlice'
import { fetchProductsPoster } from "../../redux/slices/poster/productsSlice/ProductsSlice";
import ModalThank from "../../components/Modal/ModalThank";

const Products = () => {
	const dispatch = useDispatch();
	const orderPoster = useSelector((state) => state.orderPosterSlice);

	useEffect(() => {
		dispatch(fetchProductsPoster());
	}, [dispatch]);

	return (
		<>
			<Hero />
			<ProductsList />
			{orderPoster.status === "send" &&
				orderPoster.statusPoster === "succes" && (
					<ModalThank orderPoster={orderPoster} />
				)}
			{/*<ModalThank orderPoster={orderPoster} />*/}
		</>
	);
};

export default Products;
