import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { sortBy } from "lodash";
import { fetchCategoriesPoster } from "../../redux/slices/poster/productsSlice/CategoriesSlice";
import { fetchCategoryPriority } from "../../redux/slices/categoryPriority/categoryPrioritySlice";

const ToolBarr = () => {
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.categoriesPoster);
	const scrollHeight = useSelector((state) => state.hero);
	const priorityList = useSelector((state) => state.categoryPriority);
	const priorityItems = sortBy(priorityList.items, function (o) {
		return o.priority;
	});

	useEffect(() => {
		dispatch(fetchCategoriesPoster());
	}, [dispatch]);
	useEffect(() => {
		dispatch(fetchCategoryPriority());
	}, [dispatch]);
	return (
		<div className="m-toolbar">
			<nav className="m-toolbar-nav">
				<ul className="m-toolbar-list">
					{categories.status === "loaded" &&
						priorityList.status === "loaded" &&
						priorityItems.map((item) =>
							(categories.items || [])
								.filter(
									(cat) =>
										item.priority != 0 &&
										cat.category_id == item.id &&
										cat.parent_category === "0" &&
										cat.visible[0].visible === 1
								)
								.map((category) => (
									<li key={category.category_id}>
										<NavLink
											to={category.category_id}
											onClick={() =>
												window.scrollTo(
													0,
													scrollHeight.heroHeight +
														scrollHeight.headerHeight -
														80
												)
											}
										>
											<img
												src={
													process.env
														.REACT_APP_POSTER_API_URL +
													category.category_photo
												}
												alt={category.category_name}
											/>
											<span>
												{category.category_name} {""}
											</span>
										</NavLink>
									</li>
								))
						)}
				</ul>
			</nav>
		</div>
	);
};

export default ToolBarr;
