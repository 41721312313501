import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
	const dataContacts = useSelector((state) => state.contacts);
	return (
		<footer className="o-footer">
			<div className="container">
				<div className="o-footer-top">
					<div className="row align-items-center align-items-sm-start">
						<div className="col-12 col-sm-4 col-md-5 col-lg-5 order-sm-1">
							<div className="a-footer-logo">
								<Link to="/">
									<img
										className="desktop"
										src="/image/logo.png"
										alt=""
									/>
									<img
										className="mobile"
										src="/image/logo.png"
										alt=""
									/>
								</Link>
							</div>
						</div>
						<div className="col-12 col-md-7 col-lg-12 order-sm-3 order-md-4">
							<ul className="social-list pt-4 pb-4">
								<li>
									<a
										href={`https://${dataContacts.item.facebook}`}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.36162 5.63867C10.0443 5.60761 10.2624 5.60001 12.0006 5.60001H11.9986C13.7373 5.60001 13.9546 5.60761 14.6373 5.63867C15.3186 5.66987 15.784 5.77774 16.192 5.93601C16.6133 6.09934 16.9693 6.31801 17.3253 6.67402C17.6813 7.02975 17.9 7.38682 18.064 7.80776C18.2213 8.2147 18.3293 8.67977 18.3613 9.36111C18.392 10.0438 18.4 10.2619 18.4 12.0001C18.4 13.7382 18.392 13.9558 18.3613 14.6385C18.3293 15.3196 18.2213 15.7848 18.064 16.1918C17.9 16.6127 17.6813 16.9697 17.3253 17.3255C16.9697 17.6815 16.6132 17.9007 16.1924 18.0641C15.7852 18.2224 15.3196 18.3303 14.6382 18.3615C13.9555 18.3925 13.7381 18.4001 11.9998 18.4001C10.2618 18.4001 10.0438 18.3925 9.36109 18.3615C8.67988 18.3303 8.21468 18.2224 7.80747 18.0641C7.3868 17.9007 7.02973 17.6815 6.67413 17.3255C6.31826 16.9697 6.09959 16.6127 5.93599 16.1917C5.77785 15.7848 5.66999 15.3197 5.63865 14.6384C5.60772 13.9557 5.59999 13.7382 5.59999 12.0001C5.59999 10.2619 5.60799 10.0437 5.63852 9.36098C5.66919 8.6799 5.77719 8.2147 5.93586 7.80763C6.09986 7.38682 6.31853 7.02975 6.67453 6.67402C7.03027 6.31815 7.38734 6.09948 7.80827 5.93601C8.21521 5.77774 8.68028 5.66987 9.36162 5.63867Z"
												fill="white"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M11.4265 6.75331C11.538 6.75313 11.6579 6.75319 11.7873 6.75325L12.0006 6.75331C13.7094 6.75331 13.912 6.75944 14.5868 6.79011C15.2108 6.81864 15.5495 6.92291 15.7751 7.01051C16.0737 7.12651 16.2867 7.26518 16.5105 7.48918C16.7345 7.71319 16.8732 7.92652 16.9895 8.22519C17.0771 8.45053 17.1815 8.7892 17.2099 9.4132C17.2405 10.0879 17.2472 10.2905 17.2472 11.9986C17.2472 13.7066 17.2405 13.9092 17.2099 14.5839C17.1813 15.2079 17.0771 15.5466 16.9895 15.7719C16.8735 16.0706 16.7345 16.2833 16.5105 16.5071C16.2865 16.7311 16.0739 16.8698 15.7751 16.9858C15.5497 17.0738 15.2108 17.1778 14.5868 17.2063C13.9121 17.237 13.7094 17.2437 12.0006 17.2437C10.2917 17.2437 10.0891 17.237 9.41447 17.2063C8.79046 17.1775 8.45179 17.0733 8.22605 16.9857C7.92739 16.8697 7.71405 16.731 7.49005 16.507C7.26604 16.283 7.12738 16.0702 7.01111 15.7714C6.92351 15.5461 6.81911 15.2074 6.79071 14.5834C6.76004 13.9087 6.75391 13.706 6.75391 11.997C6.75391 10.2879 6.76004 10.0863 6.79071 9.4116C6.81924 8.7876 6.92351 8.44893 7.01111 8.22332C7.12711 7.92465 7.26604 7.71132 7.49005 7.48732C7.71405 7.26331 7.92739 7.12465 8.22605 7.00838C8.45166 6.92038 8.79046 6.81638 9.41447 6.78771C10.0049 6.76104 10.2337 6.75304 11.4265 6.75171V6.75331ZM15.4169 7.81598C14.9929 7.81598 14.6489 8.15958 14.6489 8.58372C14.6489 9.00772 14.9929 9.35173 15.4169 9.35173C15.8409 9.35173 16.1849 9.00772 16.1849 8.58372C16.1849 8.15971 15.8409 7.81571 15.4169 7.81571V7.81598ZM8.71396 12C8.71396 10.185 10.1855 8.71341 12.0005 8.71334C13.8156 8.71334 15.2868 10.185 15.2868 12C15.2868 13.8151 13.8157 15.2861 12.0007 15.2861C10.1856 15.2861 8.71396 13.8151 8.71396 12Z"
												fill="white"
											/>
											<path
												d="M12.0005 9.8667C13.1787 9.8667 14.1339 10.8218 14.1339 12.0001C14.1339 13.1782 13.1787 14.1334 12.0005 14.1334C10.8223 14.1334 9.86719 13.1782 9.86719 12.0001C9.86719 10.8218 10.8223 9.8667 12.0005 9.8667Z"
												fill="white"
											/>
										</svg>
									</a>
								</li>
								<li>
									<a
										href={`https://${dataContacts.item.instagram}`}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M24 12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12C0 17.9906 4.3875 22.9547 10.125 23.8547V15.4688H7.07812V12H10.125V9.35625C10.125 6.34922 11.9156 4.6875 14.6578 4.6875C15.9703 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8297C14.3391 7.875 13.875 8.80078 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8547C19.6125 22.9547 24 17.9906 24 12Z"
												fill="white"
											/>
										</svg>
									</a>
								</li>
							</ul>
						</div>

						<div className="col-12 col-sm-8 col-md-7 col-lg-4 col-xxl-3 order-sm-2">
							<nav className="m-footer-nav pt-4 pb-4 pt-sm-0 pb-sm-0 pb-lg-0">
								<ul>
									<li>
										<Link to="/about">Про нас</Link>
									</li>
									{/*<li>
                                        <Link to="#">Франшиза</Link>
                                    </li>*/}
									<li>
										<Link to="/delivery">Доставка</Link>
									</li>
									{/*<li>
                                        <Link to="../about-us.html#contacts">
                                            Контакти
                                        </Link>
                                    </li>*/}
									<li>
										<Link to="/actions">Акції</Link>
									</li>
									{/*<li>
                                        <Link to="../cashback.html">
                                            Кешбек
                                        </Link>
                                    </li>*/}
								</ul>
							</nav>
						</div>
						<div className="col-12 col-md-5 col-lg-3 col-xxl-2 order-sm-4 order-md-3">
							<div className="line-bg"></div>
							<div className="m-footer-phone pt-4 pb-4 pt-lg-0 pb-lg-0">
								<a href={`tel:${dataContacts.item.phone1}`}>
									{dataContacts.item.phone1}
								</a>
								<a href={`tel:${dataContacts.item.phone2}`}>
									{dataContacts.item.phone2}
								</a>
								<p className="business-hours">
									{dataContacts.item.hours}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="line-bg"></div>
			<div className="container">
				<div className="o-footer-bottom">
					<div className="row">
						<div className="col-12 col-md-6">
							<div className="copyright pt-3 pb-1 pt-md-0 pb-md-0">
								©Famous delivery. All rights reserved
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="dev pt-1 pb-3 pt-md-0 pb-md-0">
								<a href="https://comebackagency.com/">
									Created by Come Back Agency
									<svg
										width="17"
										height="17"
										viewBox="0 0 17 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.0705 12.6636C13.0705 12.6636 7.48993 9.45574 7.12696 7.18884C6.99085 6.41896 6.21956 3.6388 10.2121 0.901425C10.1668 0.815882 2.54456 2.7406 0.775119 5.3069C-0.177658 6.2051 -0.268399 7.65933 0.593638 8.64308C1.04734 9.19911 1.63716 9.66959 2.36308 9.92622C4.26863 10.6533 8.94178 12.3214 13.0705 12.6636Z"
											fill="white"
										/>
										<path
											d="M7.49023 6.07669C7.49023 6.07669 8.3069 4.02366 11.8458 3.33932C12.8439 3.16823 13.7967 2.86883 14.7041 2.48388C15.3393 2.14171 15.6569 1.45737 15.4754 0.815793C15.2939 0.302535 14.7949 -0.210724 13.2523 0.0886768C12.7078 0.216991 12.2087 0.388078 11.755 0.601936C10.3939 1.28628 7.49023 2.99714 7.49023 6.07669Z"
											fill="white"
										/>
										<path
											d="M2.63574 10.6106C2.63574 10.6106 6.17463 13.4763 8.12555 14.3317C8.26166 14.4173 13.0255 17.2402 14.9765 16.9836C15.6117 16.9408 16.2015 16.5986 16.6098 16.1281C16.9274 15.786 17.0635 15.3582 16.9728 14.9305C16.882 14.5456 16.5644 14.0751 15.7024 13.7329C14.4774 13.348 13.2524 13.0486 11.9367 12.9203C11.5283 12.8775 11.12 12.8347 10.7117 12.7919C8.80611 12.4498 4.26907 11.466 2.63574 10.6106Z"
											fill="white"
										/>
									</svg>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
