import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
//import NumberFormat from 'react-number-format'

const HeaderMenu = ({ burgerActive }) => {
    const dataContacts = useSelector((state) => state.contacts)
    return (
        <div className={`m-header-menu ${burgerActive ? 'active' : ''}`}>
            <div className="m-header-menu__inner">
                <ul className="m-header-menu__list">
                    <li>
                        <Link className="active" to="/about">
                            Про нас
                        </Link>
                    </li>
                    {/*<li>
                        <Link to="/franchise"> Франшиза </Link>
                    </li>*/}
                    <li>
                        <Link to="/delivery"> Доставка </Link>
                    </li>
                    {/*<li>
                        <Link to="/contacts"> Контакти </Link>
                    </li>*/}
                    <li>
                        <Link to="/actions"> Акції </Link>
                    </li>
                </ul>
                <div className="m-header-menu__phone">
                    <a href={`tel:${dataContacts.item.phone2}`}>
                        {dataContacts.item.phone2}
                        {/*<NumberFormat format="+## (###) ### ## ##" type="tel">
                            
                        </NumberFormat>*/}
                    </a>
                </div>
                <ul className="m-header-socials__list">
                    <li>
                        <a href={`https://${dataContacts.item.facebook}`}>
                            <svg
                                width="33"
                                height="32"
                                viewBox="0 0 33 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M33 16C33 7.1625 25.6137 0 16.5 0C7.38633 0 0 7.1625 0 16C0 23.9875 6.03281 30.6062 13.9219 31.8062V20.625H9.73242V16H13.9219V12.475C13.9219 8.46563 16.384 6.25 20.1545 6.25C21.9592 6.25 23.8477 6.5625 23.8477 6.5625V10.5H21.7658C19.7162 10.5 19.0781 11.7344 19.0781 13V16H23.6543L22.9228 20.625H19.0781V31.8062C26.9672 30.6062 33 23.9875 33 16Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href={`https://${dataContacts.item.instagram}`}>
                            <svg
                                width="33"
                                height="32"
                                viewBox="0 0 33 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.5 0C7.3873 0 0 7.16344 0 16C0 24.8366 7.3873 32 16.5 32C25.6127 32 33 24.8366 33 16C33 7.16344 25.6127 0 16.5 0ZM12.8724 7.51819C13.8111 7.47677 14.111 7.46663 16.501 7.46663H16.4982C18.8889 7.46663 19.1877 7.47677 20.1264 7.51819C21.0633 7.55979 21.7031 7.70361 22.2641 7.91464C22.8434 8.13242 23.3329 8.42398 23.8224 8.89865C24.312 9.37296 24.6126 9.84906 24.8381 10.4103C25.0545 10.9529 25.203 11.573 25.247 12.4814C25.2891 13.3917 25.3001 13.6825 25.3001 16.0001C25.3001 18.3176 25.2891 18.6077 25.247 19.518C25.203 20.4261 25.0545 21.0463 24.8381 21.5891C24.6126 22.1502 24.312 22.6263 23.8224 23.1006C23.3335 23.5752 22.8433 23.8675 22.2647 24.0855C21.7047 24.2965 21.0645 24.4403 20.1277 24.4819C19.189 24.5233 18.89 24.5335 16.4999 24.5335C14.1101 24.5335 13.8103 24.5233 12.8717 24.4819C11.935 24.4403 11.2953 24.2965 10.7354 24.0855C10.157 23.8675 9.66604 23.5752 9.17708 23.1006C8.68776 22.6263 8.38709 22.1502 8.16214 21.5889C7.9447 21.0463 7.79639 20.4262 7.7533 19.5178C7.71077 18.6075 7.70013 18.3176 7.70013 16.0001C7.70013 13.6825 7.71113 13.3915 7.75312 12.4813C7.79529 11.5732 7.94379 10.9529 8.16196 10.4101C8.38746 9.84906 8.68813 9.37296 9.17763 8.89865C9.66677 8.42416 10.1577 8.1326 10.7365 7.91464C11.2961 7.70361 11.9355 7.55979 12.8724 7.51819Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.7114 9.00445C15.8647 9.00422 16.0296 9.00429 16.2076 9.00437L16.5009 9.00445C18.8505 9.00445 19.129 9.01263 20.0568 9.05352C20.9148 9.09156 21.3805 9.23059 21.6907 9.34739C22.1014 9.50206 22.3942 9.68695 22.702 9.98562C23.01 10.2843 23.2007 10.5687 23.3605 10.967C23.481 11.2674 23.6245 11.719 23.6636 12.551C23.7058 13.4505 23.7149 13.7208 23.7149 15.9981C23.7149 18.2755 23.7058 18.5457 23.6636 19.4453C23.6244 20.2773 23.481 20.7288 23.3605 21.0293C23.201 21.4275 23.01 21.7111 22.702 22.0096C22.394 22.3082 22.1016 22.4931 21.6907 22.6478C21.3809 22.7651 20.9148 22.9038 20.0568 22.9418C19.1292 22.9827 18.8505 22.9916 16.5009 22.9916C14.1511 22.9916 13.8726 22.9827 12.9449 22.9418C12.0869 22.9034 11.6212 22.7644 11.3108 22.6476C10.9002 22.4929 10.6068 22.3081 10.2988 22.0094C9.99081 21.7107 9.80014 21.427 9.64027 21.0286C9.51982 20.7281 9.37627 20.2766 9.33722 19.4446C9.29505 18.545 9.28662 18.2748 9.28662 15.996C9.28662 13.7172 9.29505 13.4484 9.33722 12.5488C9.37646 11.7168 9.51982 11.2653 9.64027 10.9645C9.79978 10.5662 9.99081 10.2818 10.2988 9.98313C10.6068 9.68446 10.9002 9.49957 11.3108 9.34455C11.621 9.22721 12.0869 9.08854 12.9449 9.05032C13.7567 9.01476 14.0713 9.0041 15.7114 9.00232V9.00445ZM21.1981 10.4214C20.6151 10.4214 20.1421 10.8795 20.1421 11.445C20.1421 12.0104 20.6151 12.469 21.1981 12.469C21.7811 12.469 22.2541 12.0104 22.2541 11.445C22.2541 10.8797 21.7811 10.421 21.1981 10.421V10.4214ZM11.9819 16.0001C11.9819 13.58 14.0053 11.6179 16.501 11.6178C18.9967 11.6178 21.0196 13.58 21.0196 16.0001C21.0196 18.4202 18.9969 20.3814 16.5011 20.3814C14.0054 20.3814 11.9819 18.4202 11.9819 16.0001Z"
                                    fill="white"
                                />
                                <path
                                    d="M16.5007 13.1556C18.1207 13.1556 19.4341 14.4291 19.4341 16.0001C19.4341 17.571 18.1207 18.8446 16.5007 18.8446C14.8806 18.8446 13.5674 17.571 13.5674 16.0001C13.5674 14.4291 14.8806 13.1556 16.5007 13.1556Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

HeaderMenu.propTypes = {
    burgerActive: PropTypes.bool,
}

export default HeaderMenu
